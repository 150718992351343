export const getFluid = (images: any, relativePath: string) => {
	return images.filter((image) => image.node.relativePath === relativePath)[0].node.childImageSharp.fluid;
};

export const getSliderGenerator = (init: {
	numberOfItems: number;
	duration: number;
	multiplier: number;
	height: number;
}) => {
	if (init.numberOfItems <= 0) {
		throw Error("numberOfItems must be greater than 0");
	}

	if (init.multiplier < 2) {
		throw Error("multiplier must be greater or equal than 2");
	}

	if (init.height <= 0) {
		throw Error("height must be greater than 0");
	}

	if (init.duration <= 0) {
		throw Error("duration must be greater than 0");
	}

	return (count: number): { count: number; top: number; action: "transform" | "reset" | "none" } => {
		if (count % init.multiplier === 0) {
			const top = (((count / init.multiplier) % init.numberOfItems) + 1) * init.height;
			return { count: count + 1, top, action: "transform" };
		} else if ((count - 1 + init.multiplier) % (init.multiplier * init.numberOfItems) === 0) {
			return { count: count + 1, top: 0, action: "reset" };
		}

		return { count: count + 1, top: -1, action: "none" };
	};
};
